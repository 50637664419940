import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import "../Navigation/styles/chakra-red.css";
import "../Navigation/styles/chakra-orange.css";
import "../Navigation/styles/chakra-yellow.css";
import "../Navigation/styles/chakra-green.css";
import "../Navigation/styles/chakra-blue.css";
import "../Navigation/styles/chakra-indigo.css";
import "../Navigation/styles/chakra-violet.css";
import NavigationRoutes from "./NavigationRoutes";

const RootChakraNavBar = () => {
  return (
    <>
      <Navbar
        bg=""
        variant="dark"
        expand="md"
        sticky="top"
        style={{ color: "white" }}
      >
        <Container>
          <Navbar.Brand href="/" style={{ border: 0 }}>
            Nerd Chakra
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="aws-practitioner-flash-cards">
                <div className="inner-red">
                  <p>AWS Practitioner</p>
                </div>
              </Nav.Link>
              <Nav.Link>
                <div className="inner-orange">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>
              <Nav.Link href="#">
                <div className="inner-yellow">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>
              <Nav.Link href="#">
                <div className="inner-green">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>
              <Nav.Link href="#">
                <div className="inner-blue">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>
              <Nav.Link href="#">
                <div className="inner-indigo">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>
              <Nav.Link href="#">
                <div className="inner-violet">
                  <p>— Coming — — Soon —</p>
                </div>
              </Nav.Link>

              {/* <div className="inner-red">
            <p>AWS Practitioner</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div>
          <div className="inner-orange">
            <p>TBD</p>
          </div> */}
            </Nav>
            {/* <NavDropdown title="Sessions" id="basic-nav-dropdown">
          <NavDropdown.ItemText></NavDropdown.ItemText>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-05">
            Turnaround Rock: Session 05:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-31-2024
          </NavDropdown.Item>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-04">
            Turnaround Rock: Session 04:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-04-2024
          </NavDropdown.Item>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-03">
            Turnaround Rock: Session 03:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-16-2024
          </NavDropdown.Item>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-02">
            Turnaround Rock: Session 02:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-19-2024
          </NavDropdown.Item>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-01">
            Turnaround Rock: Session 01:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-05-2024
          </NavDropdown.Item>
          <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-00">
            Turnaround Rock: Session 00:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-22-2023
          </NavDropdown.Item>
        </NavDropdown> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <NavigationRoutes />
    </>
  );
};

export default RootChakraNavBar;
