import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FlashCard from "./FlashCard/FlashCard";

const TestPage = () => {
  return (
    <Container>
      <Row>
        <Col className="blurb-loud">
          AWS Practitioner flashcards are meant as a study aid for taking the
          certification exam.
          <br />
          <em>
            <small>
              If you refresh page or visit at another time the cards will be
              shuffled to appear in different order.
            </small>
          </em>
        </Col>
      </Row>
      <Row>
        <Col className="centerHeight">
          {/* <div className="centerHeight"> */}
          <FlashCard />
          {/* </div> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="blurb-footer centerHeight">
            Check out{" "}
            <a
              href="https://aws.amazon.com/certification/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;Amazon AWS Certification&nbsp;
            </a>{" "}
            for latest information and exam guide.
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default TestPage;
