import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "../HomePage/HomePage";
import NavBar from "./Navigation";

// import IFrameComponent from "./IFrameComponent";
import AwsPractitioner from "../AwsPractitioner";
// import KOBTurnaroundRockSession05 from "./kids-on-bikes/KOBTurnaroundRockSession05";
// import KOBTurnaroundRockSession04 from "./kids-on-bikes/KOBTurnaroundRockSession04";
// import KOBTurnaroundRockSession03 from "./kids-on-bikes/KOBTurnaroundRockSession03";
// import KOBTurnaroundRockSession02 from "./kids-on-bikes/KOBTurnaroundRockSession02";
// import KOBTurnaroundRockSession01 from "./kids-on-bikes/KOBTurnaroundRockSession01";
// import KOBTurnaroundRockSession00 from "./kids-on-bikes/KOBTurnaroundRockSession00";

class NavigationRoutes extends React.Component {
  render() {
    return (
      // <div>
      //   <div className="row">
      //     <div className="col-md-12">
      <Router>
        {/* <NavBar /> */}
        <br />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/aws-practitioner-flash-cards"
            element={<AwsPractitioner />}
          />

          {/* <Route
                  path="/kids-on-bikes-turnaround-rock-session-05"
                  element={<KOBTurnaroundRockSession05 />}
                />
                <Route
                  path="/kids-on-bikes-turnaround-rock-session-00"
                  element={<KOBTurnaroundRockSession00 />}
                />
                <Route
                  path="/kids-on-bikes-turnaround-rock-session-01"
                  element={<KOBTurnaroundRockSession01 />}
                />
                <Route
                  path="/kids-on-bikes-turnaround-rock-session-02"
                  element={<KOBTurnaroundRockSession02 />}
                />
                <Route
                  path="/kids-on-bikes-turnaround-rock-session-03"
                  element={<KOBTurnaroundRockSession03 />}
                />
                <Route
                  path="/kids-on-bikes-turnaround-rock-session-04"
                  element={<KOBTurnaroundRockSession04 />}
                /> */}
          {/* 
      
                <Route
                  path="/scrap-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/myz-scrap.html"
                      }
                      creditName="jjpeabody"
                      creditUrl={"https://www.deviantart.com/jjpeabody/gallery"}
                    />
                  }
                /> */}

          {/* <Route path="/forbidden-lands-lead-the-way" element={<IFrameComponent targetUrl={"https://www.smokeraven.com/static/html/forbidden-lands-lead-the-way.html"}/>}/> */}
        </Routes>
      </Router>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default NavigationRoutes;
