import React from "react";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import kobTitle from "../../../src/assets/tile-logo-kids-on-bikes-white.webp";
// import kobCover01 from "../../assets/kids-on-bikes/session01/session-01-cover.webp";

const HomePage = () => {
  useEffect(() => {
    // document.body.style.backgroundImage = `url(${require("../../../src/assets/background.webp")})`;
    // document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <main
              role="main"
              className="inner cover text-center"
              style={{
                padding: "15px",
              }}
            >
              <Row>
                <Col md={12} className="blurb-loud">
                  <p>Nerd Chakra is a messy, blackboard website.</p>
                  <p>
                    This is where ideas, study aids, and sundry tidbits about
                    technology, AI, gaming, and programming will find a home.
                  </p>
                  <p>
                    Check out{" "}
                    <a href="./aws-practitioner-flash-cards">
                      Flash Cards for AWS Practitioner Exam
                    </a>
                    .
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  style={{
                    marginBottom: "25px",
                    color: "white",
                  }}
                >
                  {/* <a href="./forbidden-lands-session-12" className="tileHover">
                    <img
                      src={fblCover12}
                      alt="Forbidden Lands Session 12"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a> */}
                </Col>
                <Col md={4} style={{ marginBottom: "25px" }}>
                  {/* <a href="./forbidden-lands-session-11" className="tileHover">
                    <img
                      src={fblCover11}
                      alt="Forbidden Lands Session 11"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a> */}
                </Col>
                <Col md={4} style={{ marginBottom: "25px" }}>
                  {/* <a href="./forbidden-lands-session-10" className="tileHover">
                    <img
                      src={fblCover10}
                      alt="Forbidden Lands Session 10"
                      style={{
                        width: "100%",
                      }}
                    />
                  </a> */}
                </Col>
              </Row>
            </main>
          </div>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default HomePage;
