import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import { FlashcardArray } from "react-quizlet-flashcard";
import "./FlashCard.scss";
import cli from "../../assets/aws-icons/aws-icons-developer-tools/command-line-interface.webp";
import lambda from "../..//assets/aws-icons/aws-icons-compute/lambda.webp";
import badge from "../../assets/aws-certification-exam/AWS-Certified-Cloud-Practitioner_badge.png";
import ec2 from "../../assets/aws-icons/aws-icons-compute/ec-2.jpg";
import rds from "../../assets/aws-icons/aws-icons-database/relational-database-service.webp";
import s3 from "../../assets/aws-icons/aws-icons-storage/simple-storage-service-s3.webp";
import vpc from "../../assets/aws-icons/aws-icons-networking-content-delivery/virtual-private-cloud-vpc.webp";
import iam from "../../assets/aws-icons/aws-icons-security-identity-compliance/identify-and-access-management-iam.webp";
import benefitsOfAWS from "../../assets/aws-certification-exam/benefits-of-aws.webp";
import wellArchitected from "../../assets/aws-icons/well-architected.webp";
import managedServices from "../../assets/aws-certification-exam/managed-services.webp";
import managedServicesLogo from "../../assets/aws-certification-exam/managed-services-logo.webp";
import iamCenter from "../../assets/aws-icons/aws-icons-security-identity-compliance/iam-identity-center.webp";
import policies from "../../assets/aws-icons/aws-icons-security-identity-compliance/policies.webp";
import shield from "../../assets/aws-icons/aws-icons-security-identity-compliance/shield.webp";
import waf from "../../assets/aws-icons/aws-icons-security-identity-compliance/aws-waf.webp";
import kms from "../../assets/aws-icons/aws-icons-security-identity-compliance/key-management-service-kms.webp";
import hsm from "../../assets/aws-icons/aws-icons-security-identity-compliance/cloudhsm.webp";
import acm from "../../assets/aws-icons/aws-icons-security-identity-compliance/certificate-manager-acm.webp";
import secretsManager from "../../assets/aws-icons/aws-icons-security-identity-compliance/secrets-manager.webp";
import macie from "../../assets/aws-icons/aws-icons-security-identity-compliance/macie.webp";
import inspector from "../../assets/aws-icons/aws-icons-security-identity-compliance/inspector.webp";
import guardDuty from "../../assets/aws-icons/aws-icons-security-identity-compliance/guardduty.webp";
import securityHub from "../../assets/aws-icons/aws-icons-security-identity-compliance/security-hub.webp";
import detective from "../../assets/aws-icons/aws-icons-security-identity-compliance/detective.webp";
import artifact from "../../assets/aws-icons/aws-icons-security-identity-compliance/artifact.webp";
import cloudShell from "../../assets/aws-icons/aws-icons-developer-tools/cloudshell.webp";
import siteToSite from "../../assets/aws-icons/aws-icons-networking-content-delivery/site-to-site-vpn.webp";
import directConnect from "../../assets/aws-icons/aws-icons-networking-content-delivery/direct-connect.webp";
import regionsAndAZs from "../../assets/aws-certification-exam/regions-and-availability-zones.webp";
import elb from "../../assets/aws-icons/aws-icons-networking-content-delivery/elastic-load-balancing.webp";
import elbDiagram from "../../assets/aws-certification-exam/elb-diagram.webp";
import beanstalk from "../../assets/aws-icons/aws-icons-compute/elastic-beanstalk.webp";
import batch from "../../assets/aws-icons/aws-icons-compute/aws-batch.webp";
import lightsail from "../../assets/aws-icons/aws-icons-compute/lightsail.webp";
import workspaces from "../../assets/aws-icons/aws-icons-end-user-computing/workspaces-family.webp";
import containerVersusVM from "../../assets/aws-certification-exam/container-versus-vm.webp";
import ecs from "../../assets/aws-icons/aws-icons-containers/elastic-continer-service.webp";
import eks from "../../assets/aws-icons/aws-icons-containers/elastic-kubernetes-service.webp";
import efs from "../../assets/aws-icons/aws-icons-storage/elastic-file-system-efs.webp";
import fsx from "../../assets/aws-icons/aws-icons-storage/amazon-fsx.webp";
import ebs from "../../assets/aws-icons/aws-icons-storage/elastic-block-storage-ebs.webp";
import fsxWindows from "../../assets/aws-icons/aws-icons-storage/fsx-for-windows-file-system.webp";
import fsxLustre from "../../assets/aws-icons/aws-icons-storage/fsx-for-lustre.webp";
import storageGateway from "../../assets/aws-icons/aws-icons-storage/storage-gateway.webp";
import backup from "../../assets/aws-icons/aws-icons-storage/aws-backup.webp";
import snowball from "../../assets/aws-icons/aws-icons-storage/snowball.webp";
import snowcone from "../../assets/aws-icons/aws-icons-storage/snowcone.webp";
import cloudFront from "../../assets/aws-icons/aws-icons-networking-content-delivery/cloudfront.webp";
import accelerator from "../../assets/aws-icons/aws-icons-networking-content-delivery/global-accelerator.webp";
import route53 from "../../assets/aws-icons/aws-icons-networking-content-delivery/route-53.webp";
import athena from "../../assets/aws-icons/aws-icons-analytics/athena.webp";
import kinesis from "../../assets/aws-icons/aws-icons-analytics/kinesis.webp";
import quickSight from "../../assets/aws-icons/aws-icons-analytics/quicksight.webp";
import emr from "../../assets/aws-icons/aws-icons-analytics/amazon-emr.webp";
import cloudFormation from "../../assets/aws-icons/aws-icons-management-governance/cloud-formation.webp";
import catalog from "../../assets/aws-icons/aws-icons-management-governance/service-catalog.webp";
import cloudWatch from "../../assets/aws-icons/aws-icons-management-governance/cloudwatch.webp";
import cloudTrail from "../../assets/aws-icons/aws-icons-management-governance/cloud-trail.webp";
import sqs from "../../assets/aws-icons/aws-icons-application-integration/simple-queue-service-sqs.webp";
import sns from "../../assets/aws-icons/aws-icons-application-integration/simple-notification-service-sns.webp";
import eventBridge from "../../assets/aws-icons/aws-icons-application-integration/event-bridge.webp";
import codeCommit from "../../assets/aws-icons/aws-icons-developer-tools/codecommit.webp";
import trustedAdvisor from "../../assets/aws-icons/aws-icons-management-governance/trusted-advisor.webp";
import organizations from "../../assets/aws-certification-exam/aws-organizations-diagram.webp";
import controlTower from "../../assets/aws-icons/aws-icons-management-governance/control-tower.webp";
import awsOrganizations from "../../assets/aws-icons/aws-icons-management-governance/organizations.webp";
import budgets from "../../assets/aws-icons/aws-icons-cloud-financial-management/budgets.webp";

function FlashCard() {
  const [placeholderImageUrl, setPlaceholderImageUrl] = useState("");
  useEffect(() => {
    setPlaceholderImageUrl("https://fakeimg.pl/100x100/14700b/909090?text=+");
  }, []);
  const cards = [
    {
      id: 1,
      frontHTML: (
        <div className="div-card">
          <img
            src={cli}
            alt="Command Line Interface"
            style={{ width: "100px", height: "100px" }}
          />
          <p>
            What is <strong>AWS CLI</strong>?<br />
            how/where to run it?
          </p>
        </div>
      ),
      backHTML: (
        <>
          <div className="centerHeight">
            CLI = Command Line Interface
            <br />
            Access CLI by installing it.
          </div>
        </>
      ),
    },
    {
      id: 2,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={lambda}
              alt="lambda"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Lambda</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>
              "Run code without thinking about servers (serverless computing)."
            </em>
            <br />
            Code that runs in response to an event on a server that you don't
            have to buy or manage.
            <br />
            Could have been called AWS Scripts or AWS Functions.
          </p>
        </div>
      ),
    },

    {
      id: 3,
      frontHTML: (
        <div className="div-card">
          <img
            src={badge}
            alt="AWS Certified Cloud Practitioner Badge"
            style={{ width: "100px", height: "100px" }}
          />
          <p>
            What are the <strong>five core concepts</strong> that will be used
            to breakdown AWS services for exam?
            <br />
            i.e. what categories of things are needed to run your website?
          </p>
        </div>
      ),
      backHTML: (
        <>
          <div className="centerHeight">
            <ul style={{ textAlign: "left" }}>
              <li>
                Compute: <small>Elastic Compute Cloud (EC2)</small>
              </li>
              <li>
                Database: <small>Relational Database Service (RDS)</small>
              </li>
              <li>
                Storage: <small>Simple Storage Service (S3)</small>
              </li>
              <li>
                Network: <small>Virtual Private Cloud (VPC)</small>
              </li>
              <li>
                Security: <small>Identity and Access Management (IAM)</small>
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      id: 4,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={ec2}
              alt="Elastic Cloud Compute"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>EC2</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>Elastic Cloud Compute</em>
            <br />
            "Virtual Servers in the Cloud".
          </p>
        </div>
      ),
    },
    {
      id: 5,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={rds}
              alt="Relational Database Service"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>RDS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>Relational Database Service</em>
            <br />
            "Managed Relational Database Service"
          </p>
        </div>
      ),
    },
    {
      id: 6,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={s3}
              alt="Simple Storage Service"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>S3</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>Simple Storage Service</em>
            <br />
            "Scalable Storage in the Cloud"
          </p>
        </div>
      ),
    },

    {
      id: 7,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={vpc}
              alt="Virtual Private Cloud"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>VPC</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>Virtual Private Cloud</em>
            <br />
            "Isolated Cloud Resources"
          </p>
        </div>
      ),
    },

    {
      id: 8,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={iam}
              alt="Identity and Access Management"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>IAM</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <em>Identity and Access Management</em>
            <br />
            "Manage Access to AWS Resources"
          </p>
        </div>
      ),
    },

    {
      id: 9,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={iam}
              alt="Identity and Access Management"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>benefits of AWS</strong>?
              <br />
              This will be eight categories. Describe term, benefit, and example
              for each. (use pen/paper if needed)
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <img
            src={benefitsOfAWS}
            alt="Benefits of AWS"
            style={{ minHeight: "300px", maxHeight: "100%", minWidth: "450px" }}
          />
        </div>
      ),
    },

    {
      id: 10,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={wellArchitected}
              alt="AWS Well Architected Framework"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the{" "}
              <strong>six pillars of AWS Well-Architected framework</strong>?
              <br />
              Have name and explanatory blurb for each pillar.
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              <strong>Operational Excellence</strong> focuses on running and
              monitoring systems.
            </li>
            <li>
              <strong>Security</strong> focuses on protecting information and
              systems.
            </li>
            <li>
              <strong>Reliability</strong> focuses on workloads performing their
              intended functions and how to recover quickly from failure to meet
              demands
            </li>
            <li>
              <strong>Performance Efficiency</strong> focuses on structured and
              streamlined allocation of IT and computing resources.
            </li>
            <li>
              <strong>Cost Optimization</strong> focuses on avoiding unnecessary
              costs.
            </li>
            <li>
              <strong>Sustainability</strong> focuses on minimizing the
              environmental impacts of running cloud workloads.
            </li>
          </ul>
        </div>
      ),
    },

    {
      id: 11,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={managedServicesLogo}
              alt="AWS Managed Services"
              style={{ maxHeight: "100px" }}
            />
            <p>
              What are the{" "}
              <strong>three types of Managed Services models</strong>?
              <br />
              Know the acronyms, parts of stack (hint: 9 pieces in stack), and
              what AWS is responsible for compared to customer.
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <img
            src={managedServices}
            alt="Managed Services"
            style={{ minHeight: "300px", maxHeight: "100%", minWidth: "450px" }}
          />
        </div>
      ),
    },
    {
      id: 12,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the definition of&nbsp;
              <strong>IaaS</strong> and AWS responsibilities?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <strong>Iaas</strong>: Internet as a Service
            <br />
            AWS handles physical resources in data center.
          </p>
        </div>
      ),
    },
    {
      id: 13,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the definition of&nbsp;
              <strong>PaaS</strong> and AWS responsibilities?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <strong>PaaS</strong>: Platform as a Service
            <br />
            AWS handles runtime, middleware, and OS. Code and data up to you..
          </p>
        </div>
      ),
    },
    {
      id: 14,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the definition of&nbsp;
              <strong>SaaS</strong> and AWS responsibilities?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <strong>Saas</strong>: Software as a Service
            <br />
            AWS handles everything, you just use the software.
          </p>
        </div>
      ),
    },

    {
      id: 15,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={iamCenter}
              alt="IAM Identity Center"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>IAM Identity Center</strong>?
              <br />
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Manage workforce user access to{" "}
            <strong>multiple AWS accounts</strong> and cloud applications.
            <br />
            Note: requires use of AWS Organizations.
          </p>
        </div>
      ),
    },

    {
      id: 16,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is preferred way to manage access to{" "}
              <strong>
                console, CLI, and programmatic (i.e. access key IDs, secret
                access keys)
              </strong>
              ?
              <br />
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Use <strong>IAM Identity Center</strong> because IAM is only for
            single AWS accounts without use of Organizations.
          </p>
        </div>
      ),
    },
    {
      id: 17,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are three things <strong>only ROOT account can do</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>Close AWS Account</li>
            <li>Change AWS Support Plan</li>
            <li>Change AWS Account Settings</li>
          </ul>
        </div>
      ),
    },
    {
      id: 18,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is the three key{" "}
              <strong>differences between a role and a group</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              Focus: Roles focus on perms, while groups focus on organizing
              users.
            </li>
            <li>
              Usage: Roles are applied to individuals or services, whereas
              groups are used to manage multiple users collectively.
            </li>
            <li>
              Flexibility: Roles offer fine-grained access control, while groups
              simplify management by allowing batch assignment of perms.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 19,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={policies}
              alt="AWS Policies"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is an <strong>AWS Policy</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            An Amazon Web Services (AWS) policy is a document that defines perms
            in AWS. <br />
            It determines what actions a user, group, or service (such as an AWS
            resource) can or cannot perform on specific AWS resources. <br />
            Policies are written in JSON (JavaScript Object Notation) format
          </p>
        </div>
      ),
    },
    {
      id: 20,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>three types of MFA devices</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              Virtual: app like MS Authenticator, Google Authenticator, or Authy
            </li>
            <li>U2F: Universal Second Factor such as Yubi thumbdrive</li>
            <li>Other MFA Hardware: Gemalto thumbdrive and other hardware</li>
          </ul>
        </div>
      ),
    },
    {
      id: 21,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>seven IAM best practices</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>Use IAM user for day-to-day, not root</li>
            <li>Use roles to give perms to services</li>
            <li>Don't share credentials</li>
            <li>Assign perms to groups rather than users</li>
            <li>Give least perms (least privilege)</li>
            <li>Enforce MFA</li>
            <li>Use IAM Credentials Report to audit perms</li>
          </ul>
        </div>
      ),
    },
    {
      id: 22,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service is helpful if you want{" "}
              <strong>DDoS protection</strong> (and what does DDoS mean)?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            DDoS means Distributed Denial of Service. <br />
            AWS Shield is the service that helps protect against DDoS attacks.{" "}
            <br />
            Note: AWS Shield has an advanced tier for more protection.
          </p>
        </div>
      ),
    },
    {
      id: 23,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={shield}
              alt="AWS Shield"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Shield</strong>?
              <br />
              What does it protect against? What does Standard cost? What are
              advantages of Advanced?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Shield is the service that helps protect against DDoS attacks.{" "}
            <br />
            Standard is free and protects against most common DDoS attacks.{" "}
            <br />
            Advanced gives you 24/7 DDoS team access, insurance against loss,
            global edge locations, and near real-time CloudWatch diagnostics.
          </p>
        </div>
      ),
    },
    {
      id: 24,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={waf}
              alt="Web Application Firewall"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>WAF</strong>?
              <br />
              What does it protect against? What other services does it
              integrate with?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            WAF (Web Application Firewall) is a service that helps protect web
            applications from common web exploits and malicious web traffic.{" "}
            <br />
            Rules can allow or block traffic based on IP address, HTTP headers,
            URI, or body. <br />
            WAF integrates with CloudFront, API Gateway, and Application Load
            Balancer.
          </p>
        </div>
      ),
    },
    {
      id: 25,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={kms}
              alt="Key Management Service"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>KMS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>Securely generate and manage AWS encryption keys.</p>
        </div>
      ),
    },
    {
      id: 26,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={hsm}
              alt="Hardware Security Module"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>CloudHSM</strong>?
              <br />
              How is CloudHSM different than KMS?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            CloudHSM (Hardware Security Module) is managed hardware security
            modules in the cloud.
            <br />
            HSM has more limited integration but higher security than KMS.{" "}
            <br />
            This is more expensive and only used by very large companies.
          </p>
        </div>
      ),
    },
    {
      id: 27,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>three types of security keys</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              AWS Managed: used by AWS services such as aws/lambda, aws/cloud9,
              and aws/s3
            </li>
            <li>Customer Managed: you can manage policies for rotation etc.</li>
            <li>Custom Key Stores: using HSM as mentioned above.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 28,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={acm} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>ACM</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Certificate Manager
            <br />
            <em>Provision, manage, and deploy SSL/TLS Certificates.</em>
          </p>
        </div>
      ),
    },
    {
      id: 29,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={secretsManager}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>ASM</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Secrets Manager
            <br />
            <em>
              Easily rotate, manage, and retrieve secrets throughout their
              lifecycle.
            </em>
          </p>
        </div>
      ),
    },
    {
      id: 30,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={macie}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service can <strong>protect PII information</strong>? <br />
              What is PII? What service protects PII? How do you know PII was
              protected?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Macie
            <br />
            Macie scans for PII (Personally Identifiable Information) and alerts
            you if it finds any via CloudWatch or EventBridge. <br />
            For example, if any text files in S3 buckets contain PII, Macie will
            alert you.
            <em>Classifies and secures your business-critical content.</em>
          </p>
        </div>
      ),
    },
    {
      id: 31,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={inspector}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Inspector</strong>? <br />
              What other services does Inspector use to{" "}
              <strong>automate ticketing and workflows</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Inspector scans and detects EC2 instances and Elastic Container
            Repository (ECR) for software and network vulnerabilities.
            <br />
            Inspector uses findings to automate workflow and ticketing via{" "}
            <strong>SecurityHub</strong> and <strong>EventBridge</strong>.<br />
            <em>Continual vulnerability management at scale.</em>
          </p>
        </div>
      ),
    },
    {
      id: 32,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={guardDuty}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>GuardDuty</strong>? <br />
              How other services does GuardDuty use to{" "}
              <strong>automate workflows and remediating</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            GuardDuty continuously analyzes network, account, and data access
            (CloudTrail Mgmt, S3 Events, VPC Flow, and DNS Logs) and uses
            machine learning to detect threats.
            <br />
            GuardDuty uses findings to automate workflow and remediation via{" "}
            <strong>CloudWatchh</strong> and <strong>Lambda</strong>.<br />
            <em>
              Intelligent Threat Detection to Protect Your AWS Accounts and
              Workloads.
            </em>
          </p>
        </div>
      ),
    },
    {
      id: 33,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={guardDuty}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Config</strong>? <br />
              Gives some examples.
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Config can track and audit all of your configurations.
            <br />
            Examples:
            <ul style={{ textAlign: "left" }}>
              <li>Receive alert if S3 bucket becomes publically available.</li>
              <li>Alert when unauthorized port opened in security group.</li>
              <li>Show when configs change for compliance audit.</li>
            </ul>
            <em>Track Resource Inventory and Changes.</em>
          </p>
        </div>
      ),
    },
    {
      id: 34,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={securityHub}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS SecurityHub</strong>?
              <br />
              What other AWS Service is required for SecurityHub?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Consolidated dashboard of all security concerns.
            <br />
            SecurityHub requires use of AWS Config.
            <br />
            <em>AWS's security and compliance center.</em>
          </p>
        </div>
      ),
    },
    {
      id: 35,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={detective}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Detective</strong>?
              <br />
              What other AWS Service is required for Detective?
              <br />
              What services does Detective aggregate and what services does it
              feed for visualization?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Detective helps get at <strong>root cause</strong> of an issue by
            using GuardDuty.
            <br />
            Detective requires use of AWS GuardDuty.
            <br />
            GuardDuty organizes data from CloudTrail Logs, VPC Flow Logs, and
            GuardDuty to provide context and visualization to SecurityHub and
            GuardDuty.
            <br />
            Investigate and analyze potential security issues.
          </p>
        </div>
      ),
    },
    {
      id: 36,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={artifact}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What AWS service is useful to prove{" "}
              <strong>documentation for compliance</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Arifact.
            <br />
            <em>AWS compliance reports and agreements.</em>
          </p>
        </div>
      ),
    },
    {
      id: 37,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 01</h4>
              Under the AWS Shared Responsibility Model, which of the following
              is a responsibility of AWS?
              <ol style={{ textAlign: "left" }}>
                <li>
                  Architecting an eCommerce application to handle failures
                </li>
                <li>Securing the physical data center</li>
                <li>Setting up IAM users for all developers on the team</li>
                <li>Ensuring customer data is secure</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Architecting an eCommerce application to handle failures. Amazon
                services help applications handle failovers, but it's your
                responsibility to architect application to take advantage of
                services.
              </li>
              <li>
                <strong>Securing the physical data center.</strong> Amazon is
                responsible for physical security of data centers. They use
                physical barriers around buildings, employ guards, surveillance
                equipment and so on.
              </li>
              <li>
                Setting up IAM users for all developers on the team. It is the
                your responsibility to set up and maintain IAM users for your
                organization.
              </li>
              <li>
                Ensuring customer data is secure. Amazon offers services that
                can help protect customer’s data, but it is your responsibility
                to ensure data you collect about your customers is secure.
              </li>
            </ol>
            Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a>
          </p>
        </div>
      ),
    },
    {
      id: 38,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 02</h4>
              Which of the following is considered a security best practice when
              using Identity and Access Management (IAM)?
              <ol style={{ textAlign: "left" }}>
                <li>Hard-code credentials into your applications</li>
                <li>Generate a secret key ID for every IAM user</li>
                <li>
                  Grant the least number of perms required for a user to do
                  their job
                </li>
                <li>
                  Enable single-factor authentication for the root account
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>
              Hard-code credentials inside your applications. Hard-coding
              credentials is never good. To grant service access to another
              service, use role. If user name & password needed (i.e. database),
              Secrets Manager is best option.
            </li>
            <li>
              Generate a secret key ID for every IAM user. Secret keys enable
              programmatic access to issue commands via CLI or SDK. Only
              generate keys for users who need this (admin & developers).
            </li>
            <li>
              <strong>
                Grant the least number of perms required for a user to do their
                job
              </strong>
              . Principle of least privilege: users only granted minimum perms
              needed to accomplish task. IAM allows granular perms.
            </li>
            <li>
              Enable single-factor authentication for the root account. This is
              a distractor. It best practice to enable MULTI-factor
              authentication (MFA) for root account.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 39,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 03</h4>
              You’re creating a web application that runs on an EC2 instance.
              The application needs to upload photos to an S3 bucket. What’s the
              best way to handle perms in this scenario?
              <ol style={{ textAlign: "left" }}>
                <li>
                  Create a role with perms on S3 bucket, & then assign the role
                  to the EC2 instance
                </li>
                <li>
                  Create an IAM user for application & store the credentials in
                  application’s code
                </li>
                <li>Attach a policy to EC2 instance that grants perms to S3</li>
                <li>Create a user group that includes EC2 instance</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>
              <strong>
                Create a role with perms on S3 bucket, and then assign role to
                the EC2 instance.
              </strong>{" "}
              IAM role allows service to temporarily have perm of role;
              recommended way to grant perms between services.
            </li>
            <li>
              Create an IAM user for application & store credentials in the
              application’s code. IAM users should generally be created for
              humans that need to work with AWS. Storing credentials in code is
              not good. IAM role is preferred way to handle.
            </li>
            <li>
              Attach a policy to EC2 instance that grants perms to S3. Policies
              DO grant perms, but in this case, policy should be attached to
              role (which is then assumed by the EC2 instance) & not EC2
              instance.
            </li>
            <li>
              Create a user group that includes EC2 instance. This is
              distractor. Groups are collection of users; answer does not make
              sense in this context. Role is preferred way to handle.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 40,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 04</h4>
              You’re part of the Information Security team at your company, and
              hackers have just breached your systems. Which AWS service can you
              use to help you identify the root cause of the incident?
              <ol style={{ textAlign: "left" }}>
                <li>Amazon Macie</li>
                <li>Amazon Inspector</li>
                <li>AWS Security Hub</li>
                <li>Amazon Detective</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>
              Amazon Macie. Amazon Macie scans for, and analyzes, personally
              identifiable information, or PII, or S3 buckets.
              https://aws.amazon.com/macie/
            </li>
            <li>
              Amazon Inspector. Amazon Inspector monitors EC2 instances and ECR
              repositories for software vulnerabilities and network exposure.
              https://aws.amazon.com/inspector/
            </li>
            <li>
              AWS Security Hub. Although AWS Security Hub can be used to view
              visualizations and findings of Amazon Detective, it is Detective
              that is primarily used to identify the root cause of the security
              incident. https://aws.amazon.com/security-hub/
            </li>
            <li>
              <strong>Amazon Detective</strong>. Amazon Detective organizes
              information from other sources, including GuardDuty, and provides
              visualizations, context and detailed findings to help you identify
              the root cause of a security incident.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 41,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 05</h4>
              Your boss has asked you to help with a Compliance audit. The
              auditors want to know that AWS is compliant with respect to the
              Payment Card Industry, or PCI. How should you go about accessing
              this information?
              <ol style={{ textAlign: "left" }}>
                <li>AWS Artifact</li>
                <li>AWS Compliance</li>
                <li>AWS Security Hub</li>
                <li>AWS Trusted Advisor</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>
              <strong>AWS Artifact</strong>. AWS Artifact is free, self-service
              portal to access reports & agreements for AWS’s compliance and
              security.
            </li>
            <li>
              AWS Compliance. This is distractor. AWS Systems Manager DOES
              contain feature called Compliance, but it's not related to
              Compliance reports or agreements that question asks for.
            </li>
            <li>
              AWS Security Hub. AWS Security Hub provides consolidated view of
              security findings in account . AWS Artifact is the service you
              need for reports & agreements related to AWS compliance and
              security.
            </li>
            <li>
              AWS Trusted Advisor. AWS Trusted Advisor provides recommendations
              to help you follow best practices. AWS Artifact is the service you
              need for reports and agreements related to AWS’s compliance and
              security.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 42,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Security & Compliance Pratice Question 06</h4>
              There are two people on your team who need to programmatically
              access AWS using the Command Line Interface (CLI). What steps
              should you take to get them the access they need?
              <ol style={{ textAlign: "left" }}>
                <li>
                  Create a new IAM user and grant them access to the Console
                </li>
                <li>Generate access keys using IAM</li>
                <li>
                  Create a customer-managed key using the Key Management Service
                  (KMS)
                </li>
                <li>
                  Submit a request to have AWS generate keys through CloudHSM
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>
              Create IAM user & grant access to Console. You do need to create
              IAM users, but IAM user will not allow programmatic access; also
              need access keys generated thru IAM.
            </li>
            <li>
              <strong>Generate access keys using IAM</strong>. Access keys allow
              commands thru CLI or SDK. Keys generated through IAM.
            </li>
            <li>
              Create customer-managed key using KMS. This is distractor. KMS
              used to generate ENCRYPTION keys to encrypt data; used by services
              such as EBS, S3 and Lambda. ACCESS keys created by IAM & allow
              command thru CLI or SDK.
            </li>
            <li>
              Submit request to generate keys to CloudHSM. This is distractor.
              CloudHSM is used to generate ENCRYPTION keys, to encrypt your
              data. Keys are used by services (EBS, S3 and Lambda). CloudHSM
              keys managed by customer, submitting request to AWS for key wrong.
              ACCESS keys created by IAM & allow commands thru CLI or SDK.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 43,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={iamCenter}
              alt="guess"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service can you use to manage IAM Users across{" "}
              <strong>multiple AWS accounts</strong> and what other service is
              the answer dependent upon?
              <br />
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            IAM Identity Center.
            <br />
            Note: requires use of AWS Organizations.
          </p>
        </div>
      ),
    },
    {
      id: 44,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudShell}
              alt="guess"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service can you use instead of CLI to{" "}
              <strong>
                issue commands to AWS services without having to install
                anything
              </strong>
              ?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            AWS Cloudshell.
            <br />
            <em>
              A browser-based shell with AWS CLI access from the AWS Management
              Console.
            </em>
          </p>
        </div>
      ),
    },
    {
      id: 45,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt="guess"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>three cloud deployment models</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              Cloud: "public", infrastructure available to public (i.e. AWS)
            </li>
            <li>
              Hybrid: two or more clouds (private, public) bound together to
              enable data and application portability.{" "}
            </li>
            <li>
              On-Premises: "private", infrastruture solely for one organization.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 46,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={siteToSite}
              alt="guess"
              style={{ width: "100px", height: "100px" }}
            />
            &nbsp;
            <img
              src={directConnect}
              alt="guess"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is the two main{" "}
              <strong>
                VPC connectivity options that can connect on-premise with AWS
              </strong>
              ?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ul style={{ textAlign: "left" }}>
            <li>
              Site-to-Site VPN: encryped connection, uses public internet,
              slower connection, inexpensive, short setup, less secure.
            </li>
            <li>
              Direct Connect: dedicated physical connection, bypass public
              internet, faster connection, expensive, long setup, more secure.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 47,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is an <strong>AZ</strong> in terms of AWS infrastructure?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <img
              src={regionsAndAZs}
              alt="Regions and AZs"
              style={{ float: "left", maxHeight: "300px" }}
            />
          </p>
          <p>
            An AZ (Availability Zones) is a group of data centers within a
            Region (geographic location).
            <br />
            Each has redundant power, networking, and connectivity. House in
            separate facilities. (example: us-east-2a)
          </p>
        </div>
      ),
    },
    {
      id: 48,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is an <strong>Region</strong> in terms of AWS infrastructure?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <img
            src={regionsAndAZs}
            alt="Regions and AZs"
            style={{ float: "left", maxHeight: "300px" }}
          />
          Geographic location with multiple AZs. Regions are globally isolated.
          Regions are further apart with greater latency than AZs are from each
          other. (example: us-east-2)
        </div>
      ),
    },
    {
      id: 49,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is an <strong>Edge Location</strong> in terms of AWS
              infrastructure?
              <br />
              And what service makes heavy use of these locations?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            Geographic location used by AWS to store cached copies of content
            closer to customers for faster delivery. <br />
            Edge locations are used by AWS CloudFront to act as a CDN similar to
            Akamai.
          </p>
        </div>
      ),
    },
    {
      id: 50,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is customer resposible for in AWS Shared Responsibility model
              for <strong>EC2</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            For EC2, customer is responsible for software (including OS) on the
            instance, security (access control lists and security groups), and
            VPC architecture.
          </p>
        </div>
      ),
    },
    {
      id: 51,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              In terms of purchasing options, what are the{" "}
              <strong>six types of EC instances</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <ol style={{ textAlign: "left" }}>
            <li>On-Demand: pay for what you use, no long-term commitment.</li>

            <li>Reserved: long-term commitment, up to 70% discount.</li>
            <li>
              Spot: bid on unused capacity, can be terminated at any time, up to
              90% discount.
            </li>
            <li>
              Dedicated Hosts: physical EC2 server dedicated for your use.
            </li>
            <li>
              Dedicated Instances: instance running on a physical server
              dedicated for your use.
            </li>
            <li>
              Capacity Reservations: reserve capacity for EC2 instances in a
              specific AZ.
            </li>
          </ol>
        </div>
      ),
    },
    {
      id: 52,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={elb} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>ELB</strong> and four main benefits?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>Managed Service: AWS patches and gurantees uptime.</li>
              <li>Distributes load across instanaces and AZs.</li>
              <li>Health checks to handle failures.</li>
              <li>Exposes only single endpoint (DNS) to application.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 53,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={elb} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What are the four types of <strong>Load Balancers</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>Application Load Balancer (HTTP/HTTPS)</li>
              <li>Network Load Balancer (TCP)</li>
              <li>Gateway Load Balancer (VPN)</li>
              <li>Classic Load Balancer (legacy)</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 54,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={elb} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>ASG</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            Auto-Scaling Groups.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>Scales out/in based on specified instance numbers.</li>
              <li>Registers new instances with the Load Balancers.</li>
              <li>Replace unhealthy instances automatically.</li>
              <li>Run at optimal capacity for cost savings.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 55,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={beanstalk}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Elastic Beanstalk</strong>? Is it a PaaS, IaaS, or
              Saas? And what other service is EBS
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            Elastic beanstalk is a PaaS (Platform as a Service), you deal only
            with applications and data.
            <br />
            PaaS handles infrastructure for you by providing EC2 instances, load
            balancers, auto-scaling groups, databases and so on.
            <br />
            Elastic Beanstalk uses CloudFormation templates to deploy formation.
            <br />
            <em>Run and Manage Web Apps.</em>
          </p>
        </div>
      ),
    },
    {
      id: 56,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={batch}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Batch</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>Managed service for running batch jobs.</li>
              <li>Batch jobs are defined as Docker images.</li>
              <li>
                AWS handles everything else such as instances, schedulers,
                queues, retries, and so on.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 57,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={lightsail}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon Lightsail</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Enables folks with little to no cloud experience to launch
                website easily and quickly. Think "lite" sail.
              </li>
              <li>Good for simple applications and websites.</li>
              <li>Pricing is predictable and less expensive.</li>
            </ol>
            <em>Launch and Manage Virtual Private Servers</em>
          </p>
        </div>
      ),
    },
    {
      id: 58,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={workspaces}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are <strong>Amazon Workspaces</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={elbDiagram}
              alt="ELB Diagram"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <em>Desktops in the Cloud</em>
            <br />
            Good for a remote workforce.
            <br />
            Akin to VDI (Virtual Desktop Infrastructure) like Citrix.
          </p>
        </div>
      ),
    },
    {
      id: 59,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are <strong>containers</strong> and what is the difference
              between VM and container?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <img
              src={containerVersusVM}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p>
          <p>
            Containers are like a mini-virtual machine. Virtual machines are
            slices of the underlying hardware to create virtual machines that
            are separate.
            <br />
            Unlike virtual machines, containers are a slice of underlying OS
            instead of the hardware.
            <br />
            <strong>
              This means that containers do not have an OS per instance
            </strong>{" "}
            which makes them more lightweight and quicker than VMs.
          </p>
        </div>
      ),
    },
    {
      id: 60,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={ecs} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>ECS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elastic Container Service.
            <br />
            Used to start, stop, and run Docker containers in the cloud.
            <br />
            Integrates with many other services such as: IAM, Load Balancers,
            Auto-Scaling Groups, VPN, and so on.
            <br />
            <em>Highly secure, reliable, and scalable way to run containers</em>
          </p>
        </div>
      ),
    },
    {
      id: 61,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={eks} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>EKS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elastic Kubernetes Service.
            <br />
            Kubernetes is Google's container orchestration platform.
            <br />
            EKS is Kubernetes as a service on AWS.
            <br />
            <em>The most trusted way to start, run, and scale Kubernetes.</em>
          </p>
        </div>
      ),
    },
    {
      id: 62,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={eks} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>Fargate</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Serverless computing for containers.
            <br />
            <em>
              Serverless, pay-as-you-go compute engine that lets you focus on
              building applications without managing servers.
            </em>
          </p>
        </div>
      ),
    },
    {
      id: 63,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={s3} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What are the six <strong>S3 Storage Classes</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                S3 Standard: frequently accessed data; no minimum duration.
              </li>
              <li>
                S3 Standard-IA: long-lived infrequently accessed data; 30-day
                minimum duration.
              </li>
              <li>
                S3 Intelligent-Tiering: long-lived data with unknown access
                patterns; 30-day minimum duration.
              </li>
              <li>
                S3 One Zone-IA: long-lived infrequently accessed non-critical
                data that doesn't require multiple AZs; 30-day minimum duration.
              </li>
              <li>
                S3 Glacier: long-term archive and digital preservation; 90-day
                minimum duration. Objects must be restored before deletion.
              </li>
              <li>
                S3 Glacier Deep Archive: lowest cost storage class for long-term
                retention and digital preservation; 180-day minimum duration.
                Objects must be restored before deletion.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 64,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={s3} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>S3 Transfer Acceleration</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Speeds up content transfers by routing traffic through
                CloudFront edge locations.
              </li>
              <li>Enabled at the bucket level.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 65,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={ebs} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>EBS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elastic Block Store.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>Storage for an EC2 instance.</li>
              <li>Volumn can only attach to one instance at a time.</li>
              <li>Must be in same AZ as EC2 instance.</li>
              <li>
                Default setting is EBS persists even if instance is terminated.
              </li>
              <li>
                Can create snapshot then restore it; handy for backup/recovery
                to another AZ.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 66,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={efs} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>EFS</strong>? Differences with EBS?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={place}
              alt="Container Versus Virtual Machine"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elastic File System.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>
                Can be storage for EC2 instances, lambda functions, and
                on-premise servers.
              </li>
              <li>Can be used by multiple things at once.</li>
              <li>Can be used across multiple AZs.</li>
              <li>
                Only pay for storage used; no up-front provisioning like with
                EBS. (With EBS you have guess storage needs whereas with EFS you
                only pay for what you use)
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 67,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={fsx} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>Amazon FSx</strong>? What are the four flavors?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <img
              src={fsxWindows}
              alt="FSx for Windows"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
            <img
              src={fsxLustre}
              alt="FSx for Lustre"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p>
          <p>
            Amazon FSx is a gateway storage feature. It acts as fully managed
            service for 3rd party file system.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  FSx for Windows File Server: fully managed Windows file
                  system.
                </strong>
              </li>
              <li>
                <strong>
                  FSx for Lustre: high-performance file system for
                  compute-intensive workloads.
                </strong>
              </li>
              <li>
                FSx for NetApp ONTAP: fully managed NetApp ONTAP file system.
              </li>
              <li>FSx for OpenZFS: fully managed OpenZFS file system.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 68,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={storageGateway}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What if, due to regulations or long-term migration, you need a{" "}
              <strong>service to integrate on-premises storage with AWS</strong>
              ?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Storage Gateway will help you integrate on-premises storage with
            AWS.
          </p>
        </div>
      ),
    },
    {
      id: 69,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={snowcone}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            &nbsp;
            <img
              src={snowball}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>Snow Family</strong> of products and a few
              use cases for their use?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Snow family of products are hardware devices shipped to your
            location for huge data transfer.
            <br />
            Snowcone: most compact device.
            <ol style={{ textAlign: "left" }}>
              <li>Migrating on on-premises data center to cloud.</li>
              <li>Backing up or restoring massive amouns of data.</li>
              <li>
                Edge computing and internet when you have limited internet.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 70,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={backup}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Backup</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <em>
              AWS Backup centrally manages and automates backups across AWS
              services.
            </em>
            <br />
            Can snapshot EBS volumes and RDS databases, table backups of
            DynamoDB, and backup files/apps/databases and volumes to AWS from
            on-premises using Storage Gateway.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 71,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              How can you give{" "}
              <strong>internet access to a resource on a private subnet</strong>
              ?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <em>NAT (Network Address Translation) Gateway.</em>
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 72,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>two types of VPC Endpoints</strong>? And what
              are they for?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>Gateway: used to connect to S3 and DynamoDB.</li>
              <li>Interface: used to connect to all other AWS services.</li>
            </ol>
            The VPC endpoints add an additional layer ofsecurity when connecting
            AWS services to other services without going over the public
            internet.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 73,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the three main{" "}
              <strong>differences between ACLs and Security Groups</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Network ACLs controls traffic in/out of <strong>subnet</strong>,
                whereas Security Groups control traffic in/out of an{" "}
                <strong>EC2 Instance</strong>.
              </li>
              <li>
                Network ACLs have rules that Allow and Deny; Security Groups
                have Allow only.
              </li>
              <li></li>
            </ol>

            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 74,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What do you use to <strong>secure a subnet</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Network ACL (Access Control List)
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 75,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What do you use to <strong>secure an EC2 instance</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Security Groups
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 76,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service gives you{" "}
              <strong>visibility into your VPC network traffic</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            VPC Flow Logs
            {/* <ol style={{ textAlign: "left" }}>
              <li>
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 77,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>VPC Peering</strong> and what are the two main
              caveats?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            VPC Peering allows different VPCs to behave as a single network.
            <br />
            Main caveats are:
            <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 78,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What service do you use to{" "}
              <strong>expose a lambda function to the internet</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Amazon API Gateway (which could have been called AWS Proxy
            actually).
            <br />
            Supports REST, WebSockets, and monitoring/authentication/throttling,
            and so on.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 79,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudFront}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon CloudFront</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            CloudFront is a CDN (Content Delivery Network) that is
            geographically distributed and delivers content faster by cacheing
            at edge locations.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 80,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={accelerator}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Accelerator</strong>? How is it differentfrom
              CloudFront?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Accelerator uses edge locations to deliver content faster like
            CloudFront; however, it is not a CDN but works for wide variety of
            things (TCP, UDP, TCP, etc). Accelerator does not use cacheing like
            CloudFront but rather improves speeds by how network routes traffic.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 81,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={route53}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Route 53</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Route 53 is DNS service.
            <br />
            Its called route 53 because 53 is the port number used for the
            service.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 82,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the three services if you need{" "}
              <strong>relational database</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Amazon RDS. Aurora. Redshift.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 83,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the service if you need <strong>NOSql database</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            DynamoDB.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 84,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is the services if you need{" "}
              <strong>in-memory database</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elasticache.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 85,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is the AWS service for a <strong>graph database</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Neptune.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                VPCs cannot have overlapping CIDR (Classless Inter-Domain
                Routing) ranges of IP addresses.
              </li>
              <li>
                Links are not transitive (each VPC must be directly linked to
                each other).
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 86,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the main{" "}
              <strong>
                use cases for each of the four main types of database services
              </strong>
              ?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Relational (rds, aurora, redshift) is for traditional apps, CRM,
                and eCommerce.
              </li>
              <li>
                NoSQL (dynamodb) is for mobile, web apps, gaming, and IoT.
              </li>
              <li>
                In-memory (elasticache) is for caching, session management.
              </li>
              <li>
                Graph (neptune) is for social networking, fraud detection, and
                recommendation engines.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 87,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is
              <strong>Amazon Redshift</strong>
              and three use cases for its use?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Amazon Redshift is a data warehousing service that can handle
            massive amounts (exabytes) of data.
            <br />
            It is an OLAP (Online Analytical Processing) database that can
            handle SQL queries.
            <br />
            Good for realtime analytics, log analysis, and combining multiple
            data sources.
            {/* <ol style={{ textAlign: "left" }}>
              
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 88,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the <strong>benefits and use cases for DynamoDB</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>High performance, very fast for read/write.</li>
              <li>Massively scalable and serveless.</li>
              <li>Highly available across three AZs.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 89,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the{" "}
              <strong>benefits and use cases for ElastiCache</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Managed cacheing service that removes complexity, integrates
                with other AWS services, and is compatible with Redis and
                Memcached.
              </li>
              <li>Read-intensive web applications.</li>
              <li>Real-time apps that require fast data access.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 90,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={athena}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon Athena</strong> and the three uses cases
              for its use?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Serveless analytics service.
            <ol style={{ textAlign: "left" }}>
              <li>Querying AWS logs (CloudFront, VPC Flow Logs, CloudTrail)</li>
              <li>Business intelligence/Analytics</li>
              <li>Querying S3 using SQL statements</li>
            </ol>
            <em>Serverless interactive analytics service.</em>
          </p>
        </div>
      ),
    },
    {
      id: 91,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={kinesis}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the four <strong>Kinesis Family</strong> of services and
              what is Kinesis?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Realtime Data Processing.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>
                Video Streams: ingests, processes, streams, and stores data.
              </li>
              <li>
                Data Streams: ingests, processes, and stores in shards. Data
                must be processed before being stored (Lambda, Data Analytics)
              </li>
              <li>
                Data Firehose: ingests, processes, and stores data without
                shards directly into storage (processing is optional).
              </li>
              <li>
                Data Analytics: analyzes streaming data and enables SQL queries
                and custom Java apps.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 92,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={quickSight}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon QuickSiight</strong> and its use cases?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Serverless dashboards and visualizations of data.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>Business intelligence/analytics.</li>
              <li>Data visualization and dashboards.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 93,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={emr} alt="" style={{ width: "100px", height: "100px" }} />
            <p>
              What is <strong>EMR</strong> and its use cases?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Elastic MapReduce: a hosted hadoop framework to process data in
            scalable clusters that also supports Apache Spark, Hive, Presto, and
            other workloads.
            <ol style={{ textAlign: "left" }}>
              <li>Big data processing</li>
              <li>Machine learning</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 94,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudFormation}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>CloudFormation</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <em>Create and Manage Resources with Templates</em>
            <br />

            <ol style={{ textAlign: "left" }}>
              <li>Time savings</li>
              <li>Repeatable</li>
              <li>Changes can be made as code in a repo</li>
              <li>Estimate costs based on template</li>
              <li>Usable cross-region and cross-account</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 95,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={catalog}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Service Catalog</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Catalog allows limiting of AWS usage so engineering doesn't go past
            guardrails.
            <br />
            {/* <ol style={{ textAlign: "left" }}>
              <li>Time savings</li>
              <li>Repeatable</li>
              <li>Changes can be made as code in a repo</li>
              <li>Estimate costs based on template</li>
              <li>Usable cross-region and cross-account</li>
            </ol> */}
            <em>
              Create, share, organize, and govern your curated infrastructure as
              code (IaC) templates
            </em>
          </p>
        </div>
      ),
    },
    {
      id: 96,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={catalog}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>SSM</strong> and use cases?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            AWS Systems Manager is a Central Place to View and Manage AWS
            Resources.
            <br />
            <ol style={{ textAlign: "left" }}>
              <li>Patching and maintenance</li>
              <li>
                "Run Command" to install apps, capture logs, and change config.
              </li>
              <li>Parameter Store to manago global configurtion settings.</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 97,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudWatch}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon CloudWatch</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Provides visibility into AWS resources and applications.
            <br />
            CloudWatch includes real-time analytics, dashboards, and alarms.
            {/* <ol style={{ textAlign: "left" }}>
              <li>Patching and maintenance</li>
              <li>
                "Run Command" to install apps, capture logs, and change config.
              </li>
              <li>Parameter Store to manago global configurtion settings.</li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 98,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudTrail}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Amazon CloudTrail</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Records user activity and API usage on AWS account primarily for
            auditing.
            <br />
            CloudTrail is automatically enabled.
            <br />
            Saves a history of API calls.
            <br />
            Logs can be saved to S3 or CloudWatch.
            {/* <ol style={{ textAlign: "left" }}>
              <li>Patching and maintenance</li>
              <li>
                "Run Command" to install apps, capture logs, and change config.
              </li>
              <li>Parameter Store to manago global configurtion settings.</li>
            </ol> */}
            <em>Track User Activity and API Usage</em>
          </p>
        </div>
      ),
    },
    {
      id: 99,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={cloudWatch}
              alt="CloudWatch"
              style={{ width: "100px", height: "100px" }}
            />
            &nbsp;
            <img
              src={cloudTrail}
              alt="CloudTrail"
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the{" "}
              <strong>differences between CloudTrail and CloudWatch</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                CloudWatch monitors apps and resources; CloudTrail captures
                (i.e. leaves a trail) API calls.
              </li>
              <li>
                CloudWatch captures metrics like CPU/memory/disk, and CloudTrail
                captures creating resources, terminating resources, logins and
                so on.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 100,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What is the <strong>AWS Service Health Dashboard</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <a
              href="https://status.aws.amazon.com/"
              target="_blank"
              rel="noreferrer"
            >
              Service Health Dashboard
            </a>{" "}
            provides real-time status of AWS services.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                CloudWatch monitors apps and resources; CloudTrail captures
                (i.e. leaves a trail) API calls.
              </li>
              <li>
                CloudWatch captures metrics like CPU/memory/disk, and CloudTrail
                captures creating resources, terminating resources, logins and
                so on.
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 101,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={sqs} alt="" style={{ width: "100px", height: "100px" }} />

            <p>
              What is <strong>SQS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Simple Queue Service. SQS polls intermediate storage to pick up data
            from Producer in a loosely coupled fashion.
            <br />
            Supports multiple producers and consumers.
            <br />
            Messages deleted after read by consumer.
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                CloudWatch monitors apps and resources; CloudTrail captures
                (i.e. leaves a trail) API calls.
              </li>
              <li>
                CloudWatch captures metrics like CPU/memory/disk, and CloudTrail
                captures creating resources, terminating resources, logins and
                so on.
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 102,

      frontHTML: (
        <>
          <div className="div-card">
            <img src={sns} alt="" style={{ width: "100px", height: "100px" }} />

            <p>
              What is <strong>SNS</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Simple Notification Service is managed service for message topics
            for Pub/Sub
            {/* <ol style={{ textAlign: "left" }}>
              <li>
                CloudWatch monitors apps and resources; CloudTrail captures
                (i.e. leaves a trail) API calls.
              </li>
              <li>
                CloudWatch captures metrics like CPU/memory/disk, and CloudTrail
                captures creating resources, terminating resources, logins and
                so on.
              </li>
            </ol> */}
          </p>
        </div>
      ),
    },
    {
      id: 103,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={eventBridge}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What is <strong>Amazon EventBridge</strong>? How is it different
              than SNS and SQS?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Subscribers set <strong>rules</strong>.
              </li>
              <li>
                Publisher can <strong>describe event</strong> up front with
                Schema Registry.
              </li>
              <li>
                Publisher can be a <strong>third-party</strong> (shopify,
                zendesk, data dog).
              </li>
              <li>
                Event Bus can <strong>schedule events</strong>.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 104,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What are the three general types of AI services? And what are
              eight AI services at high level?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            AI Services, ML Services, and ML Frameworks + Infrastructure.
            <ol style={{ textAlign: "left" }}>
              <li>Rekognition Image: facial and image recognition</li>
              <li>Rekognition Video: video search and moderation</li>
              <li>
                Textract: document import and processing for NLP (natural
                language processing)
              </li>
              <li>Polly: text to speech, ebooks, announcement systems</li>
              <li>
                Transcribe: speech to text, close captioning and subtitles
              </li>
              <li>Tranlate: natural sounding language translation</li>
              <li>
                Comprehend / Comprehend Medical: NLP of text, making sense of
                unstructured data such as emails, social media posts, etc.
              </li>
              <li>Lex: conversational chatbot</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 105,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={codeCommit}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What are the <strong>four services</strong> for coding, building,
              testing, and deploying applications to AWS.
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Cloud9: browser-based IDE for coding, building, and debugging.
              </li>
              <li>
                CodeCommit: source control service for private Git repositories.
              </li>
              <li>
                CodeBuild: build service that compiles source code, runs tests,
              </li>
              <li>
                CodeDeploy: automates code deployments to EC2 or on-premises
                servers.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 106,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={trustedAdvisor}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What is <strong>AWS Trusted Advisor</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Realtime recommendations for your AWS environment based on best
            pratices.
            <ol style={{ textAlign: "left" }}>
              <li>Cost Optimization</li>
              <li>Performance</li>
              <li>Security</li>
              <li>Fault Tolerance</li>
              <li>Service Limits/quotas</li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 107,

      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />

            <p>
              What are the <strong>four levels of support plans</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            {/* <img
              src={storageGateway}
              alt=""
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            /> */}
          </p>
          <p>
            Realtime recommendations for your AWS environment based on best
            pratices.
            <ol style={{ textAlign: "left" }}>
              <li>
                Basic: quota checks, limited security checks, documentation
              </li>
              <li>
                Developer: Trusted Advisor quota and security. Business hours
                emails. Unlimited cases: one primary contact.
              </li>
              <li>
                Business: All Trusted Advisor checks, 24x7 phone/email/chat,
                unlimited cases, unlimited contacts
              </li>
              <li>
                Enterprise: everything business level, Technical Account Manager
                (TAM), concierge team for billing account questions.
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 108,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 01</h4>
              Due to strict regulatory requirements, a company needs their
              resources to be physically isolated from other AWS customers.
              Which EC2 purchasing option should they use?
              <ol style={{ textAlign: "left" }}>
                <li>Capacity Reservations</li>
                <li>Dedicated Hosts</li>
                <li>Spot Instances</li>
                <li>On-Demand Instances</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Capacity Reservations. Capacity reservations allow you to
                reserve capacity for instances in a specific availability zone.
                However, this option does not physically isolate your resources
                from other AWS customers.
              </li>
              <li>
                <strong>
                  Dedicated Hosts. A Dedicated Host is an entire physical server
                  that is used for only your resources. This meets the
                  requirements given in the question.
                </strong>
              </li>
              <li>
                Spot Instances. With a Spot Instance, you can bid (specify the
                price you want to pay) on unused EC2 capacity. The primary
                reason for using Spot is to save money. However, this option
                does not physically isolate your resources from other AWS
                customers.
              </li>
              <li>
                On-Demand Instances. With On-Demand Instances, you pay by the
                second for the instances you launch. This pricing model does not
                offer the physical isolation specified in the question.{" "}
              </li>
            </ol>
            Reference:{" "}
            <a
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
              target="_blank"
              rel="noreferrer"
            >
              Instance Purchasing Options
            </a>
          </p>
        </div>
      ),
    },
    {
      id: 109,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 02</h4>
              Under the AWS Shared Responsibility Model, which of the following
              is a responsibility of AWS?
              <ol style={{ textAlign: "left" }}>
                <li>
                  Securing the physical data center where resources are hosted
                </li>
                <li>
                  Patching the guest operating system of an EC2 instance you
                  created
                </li>
                <li>
                  Ensuring the code in a Lambda function follows security best
                  practices
                </li>
                <li>Managing users, user groups and roles in IAM</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  Securing physical data center where resources are hosted.
                  Amazon is responsible for physical security of data center,
                  including barriers, surveillance, security & so on.
                </strong>
              </li>
              <li>
                Patching guest OS of an EC2 instance you created. EC2 instance
                is considered IaaS & in this model, you are responsible for
                operating system patching & updates.
              </li>
              <li>
                Ensuring code in Lambda function follows security best
                practices. Lambda is a serverless technology, which means all
                underlying compute & infrastructure is handled for you. However,
                you ARE responsible for ensuring security of Lambda function
                code.
              </li>
              <li>
                Managing users, user groups & roles in IAM. AWS provides IAM
                service, but it is your responsibility to set up and manage
                users, user groups and roles.
              </li>
            </ol>
            Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a>
          </p>
        </div>
      ),
    },
    {
      id: 110,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 03</h4>
              You want to connect your on-premises data center to AWS, without
              going over the public internet. Which connection option should you
              use?
              <ol style={{ textAlign: "left" }}>
                <li>PrivateLink</li>
                <li>Site-to-Site VPN</li>
                <li>Storage Gateway</li>
                <li>Direct Connect</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                PrivateLink. PrivateLink is used to connect AWS resources in one
                Virtual Private Network (VPC) to another VPC. It can’t be used
                to connect to on-premises resources. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/whitepapers/latest/aws-vpc-connectivity-options/aws-privatelink.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPN: Private Link
                </a>
              </li>
              <li>
                Site-to-Site VPN. Site-to-Site VPN connections go over the
                public internet, so would not fulfill the requirements in this
                scenario. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/vpn/latest/s2svpn/VPC_VPN.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPN: Site-to-Site
                </a>
              </li>
              <li>
                Storage Gateway. Storage Gateway allows you to store on-premises
                resources in the cloud, such as for backup purposes. However,
                Storage Gateway does not establish the actual connection between
                AWS and on-premises data centers. To get a private connection,
                you want to use Direct Connect. Reference:{" "}
                <a
                  href="https://aws.amazon.com/storagegateway/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storage Gateway
                </a>
              </li>
              <li>
                <strong>
                  Direct Connect. Direct Connect offers a dedicated physical
                  connection from an on-premises data center to AWS. It does not
                  go over the public internet.
                </strong>
                &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/directconnect/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DirectConnect
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 111,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 04</h4>
              You work for a media company that allows users to upload and share
              videos. What AWS service can you use to speed up delivery of this
              content to end users?
              <ol style={{ textAlign: "left" }}>
                <li>S3</li>
                <li>Direct Connect</li>
                <li>CloudFront</li>
                <li>Multiple Availability Zones</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                S3. S3 is used to store objects/files. S3 does have a feature
                called S3 Transfer Acceleration that speeds up transfer of large
                S3 objects. However, CloudFront is AWS’s content delivery
                network (CDN), and its primary goal is to speed up delivery of
                content to end users.
              </li>
              <li>
                Direct Connect. Direct Connect comes into play with a hybrid
                architecture. It offers a dedicated physical connection from an
                on-premises data center to AWS. &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/directconnect/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DirectConnect
                </a>{" "}
              </li>
              <li>
                <strong>
                  CloudFront. CloudFront is AWS’s content delivery network
                  (CDN), and its primary goal is to speed up delivery of content
                  to end users, especially media files like videos and images.
                </strong>
                &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/cloudfront/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CloudFront
                </a>
              </li>
              <li>
                Multiple Availability Zones. While it’s important to use
                multiple Availability Zones to ensure applications are highly
                available, this does not speed up delivery of content to users.
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 112,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 05</h4>
              You have a large number of files that need to be accessed by
              multiple EC2 instances, Lambda functions and on-premises servers.
              What AWS service should you use to meet this requirement?
              <ol style={{ textAlign: "left" }}>
                <li>Elastic Block Store (EBS)</li>
                <li>Elastic File System (EFS)</li>
                <li>S3 Glacier Deep Archive</li>
                <li>Relational Database Service (RDS)</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Elastic Block Store (EBS). Elastic Block Store (EBS) volumes can
                be thought of as hard drives for a single EC2 instance. While
                they can store files, they cannot be attached to (or accessed
                by) more than one instance so would not meet the requirements of
                this question. &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/ebs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  EBS
                </a>
              </li>
              <li>
                <strong>
                  Elastic File System (EFS) - CORRECT. Elastic File System (EFS)
                  is a file system that can be accessed by multiple services at
                  a time, including on-premises servers. &nbsp; Reference:{" "}
                  <a
                    href="https://aws.amazon.com/efs/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    EFS
                  </a>
                </strong>
              </li>
              <li>
                S3 Glacier Deep Archive. S3 Glacier Deep Archive should be used
                to store data that rarely needs to be accessed. The default
                retrieval time is 12 hours. This is a solution for archiving,
                not for active file storage/access. &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/s3/storage-classes/glacier/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Glacier
                </a>
              </li>
              <li>
                Relational Database Service (RDS). The Relational Database
                Service stores data in structured tables, with relationships
                between them. This is not a solution for storing/sharing files
                across multiple services. &nbsp; Reference:{" "}
                <a
                  href="https://aws.amazon.com/rds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  RDS
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 113,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 06</h4>
              Your team has been tasked with reducing your AWS spend on compute
              resources. You’ve identified several interruptible workloads that
              are good candidates for cost savings. What EC2 pricing model would
              make the most sense in this scenario?
              <ol style={{ textAlign: "left" }}>
                <li>Spot Instances</li>
                <li>Reserved Instances</li>
                <li>On-Demand Instances</li>
                <li>Dedicated Hosts</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  Spot Instances. Bid (specify price you want) on unused EC2
                  capacity. Can save up to 90% over On-Demand Instances.
                  Instances can be shut down at any time. However, because
                  identified workloads are interruptible, this would be valid
                  solution.
                </strong>
              </li>
              <li>
                Reserved Instances. Can provide savings of up to 70%. This makes
                sense for long-running workloads, such as databases, & can be
                reserved for 1-3 years. While this option does provide cost
                savings, Spot Instances will save even more, & can be used with
                interruptible workloads mentioned i question.
              </li>
              <li>
                On-Demand Instances. You pay, by the second, for instances you
                launch. This option would not provide cost savings mentioned in
                question.{" "}
              </li>

              <li>
                Dedicated Hosts. An entire physical server used for only your
                resources. This option would not provide cost savings mentioned
                in question. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instance Purchasing Options
                </a>
              </li>
            </ol>
          </p>
        </div>
      ),
    },
    {
      id: 114,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 07</h4>
              You are building a simple website for a friend to showcase their
              hobbies. It has three webpages with text and pictures that will
              rarely change. You want to minimize the amount of effort and cost
              required to host this website. Which solution should you choose?
              <ol style={{ textAlign: "left" }}>
                <li>
                  Create an EC2 instance for the web pages, and store the
                  pictures in S3
                </li>
                <li>
                  Create an application using Elastic Beanstalk and then upload
                  the webpages and pictures
                </li>
                <li>Create a CloudFront distribution to host the files</li>
                <li>
                  Use the static website features of S3, and upload the webpages
                  and pictures to an S3 bucket
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                EC2 instance for web pages, and pictures in S3. Work, but more
                effort & cost than S3’s static website. Also, because content
                rarely changes, ideal candidate for S3 static website.
              </li>
              <li>
                Create Elastic Beanstalk app & upload webpages & pictures. While
                Elastic Beanstalk requires little effort, not most
                cost-effective option. You would pay for an EC2 instance
                underlying application. Because content rarely changes, idea
                candidate for S3 static website.
              </li>
              <li>
                CloudFront distribution to host files. This is distractor. Set
                up CloudFront distribution to pull from S3, requires more effort
                than S3 static website.
              </li>
              <li>
                <strong>
                  Use static website features of S3. Upload webpages & pictures
                  to S3 bucket. Because content rarely changes, this is ideal
                  candidate for S3 static website. S3 less expensive than other
                  options.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/WebsiteHosting.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website Hosting
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 115,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 08</h4>
              Due to regulatory requirements, your application needs to
              replicate data into a second AWS region. Which service supports
              this scenario?
              <ol style={{ textAlign: "left" }}>
                <li>Direct Connect</li>
                <li>Elastic Block Store (EBS)</li>
                <li>Relational Database Service (RDS)</li>
                <li>Storage Gateway</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Direct Connect offers a dedicated physical connection from an
                on-premises data center to AWS. You can create a Direct Connect
                connection in any region, but Direct Connect is not used to
                replicate data across regions. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/WebsiteHosting.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website Hosting
                </a>
                https://aws.amazon.com/directconnect{" "}
              </li>
              <li>
                Elastic Block Store replicates data across Availability Zones in
                a single region, but does not support replication across
                regions. Reference:{" "}
                <a
                  href="https://aws.amazon.com/ebs"
                  target="_blank"
                  rel="noreferrer"
                >
                  EBS
                </a>
              </li>
              <li>
                <strong>
                  Relational Database Service supports read replicas, where a
                  copy of your database is used for read requests. This
                  functionality is supported across regions so this would
                  fulfill requirements in question.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_ReadRepl.XRgn.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read/Replication
                </a>
              </li>
              <li>
                Storage Gateway. Storage Gateway allows you to store on-premises
                resources in cloud, such as for backup purposes. However,
                Storage Gateway does not directly support cross-region
                replication. Reference:{" "}
                <a
                  href="https://aws.amazon.com/storagegateway"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storage Gateway
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 116,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 09</h4>
              Which of the following is used to control the flow of traffic for
              an EC2 instance?
              <ol style={{ textAlign: "left" }}>
                <li>Network Access Control List (ACL)</li>
                <li>Security Group</li>
                <li>VPC Endpoint</li>
                <li>User Group</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Network Access Control List (ACL). A Network Access Control List
                (ACL) is used to control traffic at the VPC subnet level.
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/storagegateway"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storage Gateway
                </a>
                https://docs.aws.amazon.com/vpc/latest/userguide/vpc-network-acls.html{" "}
              </li>
              <li>
                <strong>
                  Security Group. A Security Group operates at the EC2 instance
                  level, and is used to control traffic in and out of the
                  system.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/vpc/latest/userguide/VPC_SecurityGroups.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPC Security Groups
                </a>
              </li>
              <li>
                VPC Endpoint. VPC endpoints allow you to access other AWS
                services through a private network (vs. going across the public
                internet). Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/vpc-endpoints.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPC Endpoints
                </a>
              </li>
              <li>
                User Group. A User Group is a group of users created through the
                Identity and Access Management (IAM) service. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_groups.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  IAM ID Groups
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 117,

      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 10</h4>
              Your company is undergoing an audit as a result of a recent
              security breach. The auditor has asked for logs that show sign-ins
              of the root account to the AWS Management Console. Which service
              would capture these events?
              <ol style={{ textAlign: "left" }}>
                <li>CloudWatch</li>
                <li>CloudTrail</li>
                <li>VPC Flow Logs</li>
                <li>IAM Credential Report</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                CloudWatch. CloudWatch is used for performance monitoring of
                applications and resources, such as CPU, memory, disk and GPU
                utilization and so on. While CloudWatch can integrate with
                CloudTrail (which logs the sign-in events mentioned in the
                question), CloudTrail is the service that would capture the
                events. Reference:{" "}
                <a
                  href="https://aws.amazon.com/cloudwatch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CloudWatch
                </a>
              </li>
              <li>
                <strong>
                  CloudTrail. CloudTrail Captures user activity and API calls on
                  an AWS account, which would include events such as sign-ins
                  from the root account.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-user-guide.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  CloudTrail
                </a>
              </li>
              <li>
                VPC Flow Logs. VPC Flow Logs capture the activity going to and
                from network interfaces in a VPC. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/vpc/latest/userguide/flow-logs.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPC Flow Logs
                </a>
              </li>
              <li>
                IAM Credential Report. The IAM Credential Report shows things
                such as status of credentials, access keys and MFA devices. This
                report could tell you when the root account last logged in, but
                it would not log every sign-in event that the question is asking
                for. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_getting-report.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  IAM Credential Report
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 118,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 11</h4>
              As your team is migrating legacy applications to AWS, you’re also
              re-architecting them to align to architecture best practices.
              Specifically, you’re looking to decouple application components.
              What is a key AWS service used to achieve loose coupling?
              <ol style={{ textAlign: "left" }}>
                <li>Simple Queue Service (SQS)</li>
                <li>Simple Notification Service (SNS)</li>
                <li>Lambda</li>
                <li>CloudFormation</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  Simple Queue Service. Using queue in application’s
                  architecture is primary way to decouple components. With
                  queue, producers send messages to queue & then consumers poll
                  queue for new messages to process. If consumer goes down,
                  messages remain in queue & work can be resumed later.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/sqs"
                  target="_blank"
                  rel="noreferrer"
                >
                  SQS
                </a>
              </li>
              <li>
                Simple Notification Service (SNS). SNS sends notifications via
                email, Lambda, HTTP, SMS & other protocols. Reference:{" "}
                <a
                  href="https://aws.amazon.com/sns"
                  target="_blank"
                  rel="noreferrer"
                >
                  SNS
                </a>
              </li>
              <li>
                Lambd function is code that runs in response to event. While
                it’s common that Lambda used with queues, SQS queue is primary
                way of decoupling components in architecture. Reference:{" "}
                <a
                  href="https://aws.amazon.com/lambda"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lambda
                </a>
              </li>
              <li>
                CloudFormation is AWS’s “infrastructure as code” solution. In
                JSON/YAML template, define resources we want to build, &
                CloudFormation builds them. Reference:{" "}
                <a
                  href="https://aws.amazon.com/cloudformation"
                  target="_blank"
                  rel="noreferrer"
                >
                  CloudFormation
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 119,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 12</h4>
              Your company is using AWS for mission-critical workloads. When
              something goes wrong with one of these workloads, you need a
              response from AWS support in less than 15 minutes. Which support
              plan guarantees this?
              <ol style={{ textAlign: "left" }}>
                <li>Business</li>
                <li>Developer</li>
                <li>Trusted Advisor</li>
                <li>Enterprise</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Business. Not recommended for mission-critical workloads, & does
                not support response times of less than 15 minutes.
              </li>
              <li>
                Developer. Not recommended for mission-critical workloads, &
                does not support response times of less than 15 minutes.
              </li>
              <li>
                Trusted Advisor. Service available across different support
                plans, though with limited checks for Basic & Developer.
                Inspects environment & makes recommendations across five
                categories based on best practices. However, not support plan
                itself, & would not meet requirements listed in question.
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/premiumsupport/technology/trusted-advisor"
                  target="_blank"
                  rel="noreferrer"
                >
                  Trusted Advisor
                </a>
              </li>
              <li>
                <strong>
                  Enterprise. Enterprise plan is recommended for
                  mission-critical workloads, & it has an SLA of less than 15
                  minutes for mission-critical systems down.
                </strong>
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/premiumsupport/plans"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support Plans
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 120,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 13</h4>
              You work on a global team of 30 people responsible for the AWS
              infrastructure that runs critical applications for the company.
              The team needs to ensure that AWS resources are provisioned and
              configured the same way, regardless of which team member is doing
              the work. Which service will help you accomplish this goal?
              <ol style={{ textAlign: "left" }}>
                <li>Elastic Beanstalk</li>
                <li>EC2</li>
                <li>Service Catalog</li>
                <li>CloudFormation</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Elastic Beanstalk is PaaS. That means you’re only responsible
                for code & not infrastructure. This answer does not make sense
                because question asks how to provision infrastructure.
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/elasticbeanstalk"
                  target="_blank"
                  rel="noreferrer"
                >
                  Elastic Beanstalk
                </a>
              </li>
              <li>
                EC2. Part of infrastructure set up in this scenario. However,
                manually setting up EC2 leaves room for errors &
                misconfiguration. CloudFormation defines resources in code,
                which means same resources & configuration every time.
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/ec2"
                  target="_blank"
                  rel="noreferrer"
                >
                  EC2
                </a>
              </li>
              <li>
                Service Catalog allows developers to choose from a portfolio of
                curated products, such as “web development” or “data analysis.”
                This does not actually provision or configure infrastructure for
                you. Reference:{" "}
                <a
                  href="https://aws.amazon.com/servicecatalog"
                  target="_blank"
                  rel="noreferrer"
                >
                  Service Catalog
                </a>
              </li>
              <li>
                <strong>
                  CloudFormation is AWS’s “infrastructure as code” solution. In
                  JSON/YAML template, define resources we want to build &
                  CloudFormation builds them. Because resources are code, same
                  infrastructure will be set up same way every time.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/cloudformation"
                  target="_blank"
                  rel="noreferrer"
                >
                  CloudFormation
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 121,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Technology Practice Question 14</h4>A bank is experimenting
              with machine learning as a way to improve customer service. Rather
              than customers inserting their debit card at the bank’s ATM, they
              would like to give customers the option to do a facial scan
              instead. What AWS service would enable them to do this?
              <ol style={{ textAlign: "left" }}>
                <li>Rekognition Image</li>
                <li>Comprehend</li>
                <li>SageMaker Image</li>
                <li>Personalize</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  Rekognition Image. Rekognition Image can be used for facial
                  recognition.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/rekognition/image-features/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Rekognition Image
                </a>
              </li>
              <li>
                Comprehend. Comprehend is used for natural language processing
                of text. Its use case is to make sense of unstructured data,
                such as social media posts, reviews, emails, etc. Reference:{" "}
                <a
                  href="https://aws.amazon.com/comprehend/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comprehend
                </a>
              </li>
              <li>
                SageMaker Image. This is a distractor. There is no service
                called SageMaker Image.
              </li>
              <li>
                Personalize. Personalize is used for doing real-time
                personalization and making recommendations (such as content
                recommendations or recommending similar items to purchase).
                Reference:{" "}
                <a
                  href="https://aws.amazon.com/personalize/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Personalize
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 122,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={awsOrganizations}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Organizations</strong> and the main benefits?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <img
              src={organizations}
              alt="AWS Organizations"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p>
          <p>
            AWS Organizations allow centralized management of more than one AWS
            account.
            <br />
            <em>Central governance and management across AWS accounts.</em>
            <ol style={{ textAlign: "left" }}>
              <li>Centralized account management including permissions.</li>
              <li>Consolidated Billing including sharing discount pricing.</li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 123,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={controlTower}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>Control Tower</strong> and how is it different
              than Organizations?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={organizations}
              alt="AWS Organizations"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <em>
              The easiest way to set up and govern a secure, compliant
              multi-account environment
            </em>
            <br />
            Control Tower allow centralized management of more than one AWS
            account. It builds on AWS Organizations and simplifies governance,
            automation of account setup and governance based on AWS best
            practices (whereas Organizations allows manual configuration).
            {/* <ol style={{ textAlign: "left" }}>
              <li>Centralized account management including permissions.</li>
              <li>Consolidated Billing including sharing discount pricing.</li>
            </ol> */}
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 124,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={budgets}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What is <strong>AWS Budgets</strong> and how is it different from
              CloudWatch billing alerts?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={organizations}
              alt="AWS Organizations"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <em>Budgets is the billing and cost management feature of AWS.</em>
            <br />

            <ol style={{ textAlign: "left" }}>
              <li>CloudWatch alerts are more limited than Budget alerts.</li>
              <li>
                Budget can alert based on actual and forecasted, but CloudWatch
                can alert on actual only.
              </li>

              <li>
                Budget can filter by region and service, whereas CloudWatch
                cannot filter by region.
              </li>
              <li>Budget is the newer service and is more robust.</li>
              <li>You have to enable CloudWatch Billing.</li>
              <li>
                You can only find CloudWatch billing metric in us-east-1 region.
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 125,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the four <strong>budget types</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={organizations}
              alt="AWS Organizations"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Cost budgets: monitor how much you are spending on AWS services
                based on threholds.
              </li>
              <li>
                Usage budgets: monitor your usage of one or more AWS services
                based on usage type groups.
              </li>
              <li>
                Savings plan budgets: monitor your usage and coverage and
                receive alert when threhhold drops below set percentage.
              </li>
              <li>
                Reservation budgets: monitor your usage of your reservations.
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 126,
      frontHTML: (
        <>
          <div className="div-card">
            <img
              src={placeholderImageUrl}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            <p>
              What are the four <strong>ways to explore costs</strong>?
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          {/* <p>
            <img
              src={organizations}
              alt="AWS Organizations"
              style={{ float: "left", maxHeight: "300px", marginRight: "40px" }}
            />
          </p> */}
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                Cost Explorer: visualize, understand, and manage your AWS costs
                and usage over time. Can drill into specific charges and can
                provide forecast based on usage.
              </li>
              <li>
                Billing Dashboard: provides a snapshot of cost by service,
                forecasted costs, etc.
              </li>
              <li>
                Cost Allocation Tags: once activated, tags that you can apply to
                your AWS resources to then filter and group charges by tag.
              </li>
              <li>
                AWS Pricing Calculator: estimate your monthly bill using pricing
                information for AWS services. Used to be called "TCO Calculator"
                and "Simple Monthly Calculator".
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://aws.amazon.com/compliance/shared-responsibility-model"
              target="_blank"
              rel="noreferrer"
            >
              AWS Shared Responsibility Model
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 127,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Billing and Pricing Practice Question 01</h4>A company’s
              Finance department wants to see a single bill for all AWS accounts
              used at the company. How can they accomplish this?
              <ol style={{ textAlign: "left" }}>
                <li>
                  By setting up an AWS Budget for each account and then using
                  the Billing Dashboard to view all costs
                </li>
                <li>
                  By using the Consolidated Billing feature of AWS Organizations
                </li>
                <li>By using AWS Cost Explorer and grouping by Accounts</li>
                <li>
                  By setting up Organizational Units, each with its own AWS
                  Budget
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight" style={{ overflow: "auto" }}>
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                AWS Budget for each account & using Billing Dashboard. Single
                bill for all accounts needs AWS Organizations, enables
                Consolidated Billing. AWS Budgets allow budget & monitor/alert
                when costs meet threshold. Although AWS Budgets within
                Organization, it is Consolidated Billing that gives single bill
                that question is asking for.
              </li>
              <li>
                <strong>
                  Using Consolidated Billing feature of AWS Organizations. Using
                  AWS Organizations, you take advantage of Consolidated Billing,
                  which gives single bill across all AWS accounts.
                </strong>
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/consolidated-billing.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Consolidated Billing
                </a>
              </li>
              <li>
                Using AWS Cost Explorer & grouping by Accounts. For single bill
                for all accounts, use AWS Organizations to enable Consolidated
                Billing. AWS Cost Explorer drills into costs using groups &
                filters. Although Cost Explorer used with an AWS Organizations,
                Consolidated Billing is feature that gives single bill question
                is asking for.
              </li>
              <li>
                By setting up OUs, each with own AWS Budget. Organizations have
                Consolidated Billing. True OUs part of AWS Organization, but
                answer is distractor. Reference to AWS Budgets is also
                distractor (sets budgets & monitor/alert on costs, not to view
                consolidated bill).
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
              target="_blank"
              rel="noreferrer"
            >
              Instance Purchasing Options
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 128,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Billing and Pricing Practice Question 02</h4>
              Your company is currently operating on-premises, but you’re
              evaluating the cost-effectiveness of moving some workloads to AWS.
              Which tool should you use to get an estimate of AWS costs?
              <ol style={{ textAlign: "left" }}>
                <li>AWS Cost Explorer</li>
                <li>Cost and Usage Report</li>
                <li>AWS Pricing Calculator</li>
                <li>AWS Budgets</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                AWS Cost Explorer. AWS Cost Explorer can be used by existing
                customers to drill into their costs (actual and forecasted)
                using groupings and filtering. This service would not be
                available to non-AWS customers. Reference:{" "}
                <a
                  href="https://aws.amazon.com/aws-cost-management/aws-cost-explorer"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS Cost Explorer
                </a>
              </li>
              <li>
                Cost and Usage Report. Allows publishing reports to S3 bucket.
                Reports can break down costs by hour, day or month, & include
                other information such as services and tags. This service would
                not be available to non-AWS customers. Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cost and Usage Report
                </a>
              </li>
              <li>
                <strong>
                  AWS Pricing Calculator. Enables creating estimates for AWS
                  services. Can also select discounted pricing options, such as
                  reserved instances & up-front payment terms for more accurate
                  estimates. This calculator is public tool available to anyone.
                </strong>
                Reference:{" "}
                <a
                  href="https://calculator.aws"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS Pricing Calculator
                </a>
              </li>
              <li>
                AWS Budgets. AWS Budgets allows setting budget & monitor/alert
                when costs meet threshold. Service used by existing AWS
                customers only. Reference:{" "}
                <a
                  href="https://aws.amazon.com/aws-cost-management/aws-budgets/"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS Budgets
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
              target="_blank"
              rel="noreferrer"
            >
              Instance Purchasing Options
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 129,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Billing and Pricing Practice Question 03</h4>
              Your Finance department needs more granularity with respect to AWS
              billing. They want to be able to see a breakdown of costs based on
              who created resources. How can they accomplish this?
              <ol style={{ textAlign: "left" }}>
                <li>
                  By setting up an AWS Organization with multiple accounts under
                  it
                </li>
                <li>By using Cost Explorer and filtering by IAM users</li>
                <li>
                  By creating an AWS Budget and creating an alert for each IAM
                  user
                </li>
                <li>
                  By activating a cost allocation tag for createdBy and then
                  using it in Cost Explorer
                </li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                AWS Organization is a collection of accounts, & enables
                consolidated billing. Possible to drill into costs for single
                account, first need to activate cost allocation tag for
                createdBy. Once activated, can use it with tools such as Cost
                Explorer.
              </li>
              <li>
                Cost Explorer does drill into costs & group/filter by criteria.
                However, no filter/group for IAM users. Also, there may be other
                identities creating resources. To achieve what question is
                asking, activate tag for createdBy, then use in Cost Explorer.
              </li>
              <li>
                AWS Budgets allow setting budget & monitor/alert when costs meet
                threshold. However, alerts don’t make sense in context of this
                question. To achieve what question is asking, activate a tag for
                createdBy, then use it in Cost Explorer.
              </li>
              <li>
                <strong>
                  By activating a cost allocation tag for createdBy and then
                  using it in Cost Explorer. Cost allocation tags enable an
                  additional layer of detail when looking at AWS costs. There
                  are user-defined tags, as well as AWS managed tags, including
                  one for createdBy. It’s free to use these tags. Once they are
                  activated, they can be used with tools such as Cost Explorer
                  to group and filter by that tag.
                </strong>
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/cost-alloc-tags.html "
                  target="_blank"
                  rel="noreferrer"
                >
                  Cost Allocation Tags
                </a>
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
              target="_blank"
              rel="noreferrer"
            >
              Instance Purchasing Options
            </a> */}
          </p>
        </div>
      ),
    },
    {
      id: 130,
      frontHTML: (
        <>
          <div className="div-card">
            <p>
              <h4>Billing and Pricing Practice Question 04</h4>
              Two of your team members are building a simple app to test out AWS
              functionality. They estimate the app will be running continuously
              for three months. Which EC2 pricing plan makes the most sense for
              this use case?
              <ol style={{ textAlign: "left" }}>
                <li>On-demand</li>
                <li>Spot instances</li>
                <li>Reserved instances</li>
                <li>Savings plan</li>
              </ol>
            </p>
          </div>
        </>
      ),
      backHTML: (
        <div className="centerHeight">
          <p>
            <ol style={{ textAlign: "left" }}>
              <li>
                <strong>
                  On-demand. On-demand instances are good for testing things
                  out, especially if they won’t be in use for a long time. This
                  option doesn’t offer any kind of discount pricing, but is the
                  best option given the scenario.
                </strong>{" "}
                Reference:{" "}
                <a
                  href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Purchasing Options
                </a>
              </li>
              <li>
                Spot instances can bid on unused EC2 capacity. Primary reason
                for Spot is save money. Instances can be shut down at any point,
                & app referenced in question will need to run continuously so
                this not best option.
              </li>
              <li>
                Reserved instances. Reserved instances can provide savings of up
                to 70%. This solution makes sense for long-running workloads,
                such as databases, and can be reserved for 1-3 years. Because
                the app referenced in the question only needs to run for three
                months, reserved instances would not be the best option.
              </li>
              <li>
                Savings plan. Savings plans combine benefits of on-demand and
                reserved instances. You receive savings on the commitment of 1-3
                years and then get on-demand pricing after that. However,
                because the app referenced in the question only needs to run for
                three months, a savings plan would not be the best option.
              </li>
            </ol>
            {/* Reference:{" "}
            <a
              href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-purchasing-options.html"
              target="_blank"
              rel="noreferrer"
            >
              Instance Purchasing Options
            </a> */}
          </p>
        </div>
      ),
    },
  ];

  // Function to shuffle the flashcard array using Fisher-Yates algorithm
  const shuffleCards = (cards) => {
    for (let i = cards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [cards[i], cards[j]] = [cards[j], cards[i]];
    }
    return cards;
  };

  return (
    <>
      <FlashcardArray
        cards={shuffleCards([...cards])}
        // cards={[...cards]}
        cycle={true}
        frontCardStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "lightgrey",
          backgroundColor: "#14700b",
          border: "5px solid black",
          margin: "0 auto",
          height: "100%",
          textAlign: "center",
          fontSize: "1.5em",
        }}
        backCardStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.4em",
          color: "white",
          backgroundColor: "grey",
          border: "5px solid black",
          margin: "0 auto",
          height: "100%",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      />
    </>
  );
}

export default FlashCard;
