import React, { useState, useEffect } from "react";
import Navigation from "./components/Navigation/Navigation";

function App() {
  useEffect(() => {
    // call api or anything
    console.log("loaded");
  });

  return (
    <>
      <Navigation bg="dark" variant="dark"></Navigation>
    </>
  );
}

export default App;
